import Container from "components/Container";

export default function Foreclosures() {
  return (
    <>
      <section className="my-8 inner-content">
        <Container>
          <h1 className="font-bold text-3xl">Foreclosure & Cancellations</h1>
          <p className="mt-4">Effective Date: Effective from 1 April 2023</p>
          <h3 className="mt-4 font-bold text-2xl">Cancellation Policy</h3>
          <p>
            If you wish to cancel your loan following the process of loan
            disbursement, the lender will charge a loan cancellation charge.
          </p>
          <h3 className="mt-4 font-bold text-2xl">Foreclosure</h3>
          <p>
            If you wish to pre-pay, whether in part or in full, your loan is
            taken as Cash Credit or Overdraft, and the lending authority will
            levy a foreclosure charge for the same.
          </p>
          <p className="mt-4 font-bold">Basic understanding</p>
          <p className="font-semibold mt-4">
            What is pre-payment or foreclosure?{" "}
          </p>
          <p>
            Prepayment or foreclosure is the repayment of a loan by a borrower,
            in part or in full ahead of the pre-determined payment schedule.
            However, the distinguishing factor is that pre-payment means early
            payment of scheduled instalments, while foreclosure means early
            payment of the entire outstanding amount leading to early closure of
            the loan term. To extend, pre-payment is partial in nature whereas
            foreclosure is the closure of the loan account before the due-date.{" "}
          </p>
          <p className="font-semibold mt-4">
            How do foreclosure charges and pre-payment penalties differ?{" "}
          </p>
          <p>
            Conceptually, both have the same meaning. The only difference is in
            the terminology as the charges levied at the time of foreclosure are
            termed as foreclosure charges and charges levied at the time of
            pre-payment of an instalment are termed as pre-payment penalties.{" "}
          </p>
          <p className="font-semibold mt-4">What is a term loan? </p>
          <p>
            A term loan means a loan for which the term for repayment is
            pre-determined. This is unlike a demand loan in which the borrower
            has to repay on demand of repayment by the lender.
          </p>
          <p className="font-semibold mt-4">
            How is a floating rate term loan different from a fixed rate term
            loan?{" "}
          </p>
          <p>
            A fixed-rate term loan refers to interest rates that remain locked
            throughout the loan period, while floating-rate term loan refers to
            interest rates that are subject to fluctuate owing to certain
            factors.{" "}
          </p>
          <p className="font-semibold mt-4">
            How is floating rate determined?{" "}
          </p>
          <p>
            Lenders determine the floating rate on the basis of certain base
            rate. Usually, the floating rate is some percentage points more than
            the base rate. Base rate is determined by taking into account the
            cost of funds of the lender.
          </p>
          <p className="font-semibold mt-4">
            Where do we find such floating rate term loans?{" "}
          </p>
          <p>
            Floating rates are generally found in loans of long-term as the cost
            of funds is likely to fluctuate in the long run. However, certain
            medium term loans also have floating interest rate depending upon
            the agreement between the lender and borrower.
          </p>
          <p className="font-semibold mt-4">
            Can a borrower make pre-payment of a term loan?{" "}
          </p>
          <p>
            Courts have, in many cases, given judgements stating that in the
            absence of specific provision in the agreement between the lender
            and the borrower (Loan Agreement), the borrower has the inherent
            right to make pre-payment of a loan. This puts light on the
            principle that every borrower has an inherent right to free himself
            from the loan.[2] In case a lender requires that the loan amount
            should not be prepaid, such a restriction must be expressly
            mentioned in the Loan Agreement.{" "}
          </p>
          <p className="font-semibold mt-4">
            Can a lender levy foreclosure charges/pre-payment penalty?{" "}
          </p>
          <p>
            Unlike the provisions relating to pre-payment of loan by the
            borrower, the provisions for levy of foreclosure charges/pre-payment
            penalties are largely governed by the terms of the Loan Agreement. A
            lender can levy only those charges which form part of the Loan
            Agreement. If provisions for levy of foreclosure charges/pre-payment
            penalties are expressly mentioned in the Loan Agreement, the lender
            can levy such charges/penalty. In absence of such provision, the
            lender does not have the right to levy such charges/penalty.
            Further, for entities regulated by RBI, it is mandatory to mention
            all kinds of charges and penalties applicable to a loan transaction
            in the loan application form.
          </p>
          <p className="font-semibold mt-4">
            What happens on prepayment of loan?{" "}
          </p>
          <p>
            Pre-payment of loan amount by the borrower has dual-impact. One is
            saving of interest cost and the other is reduction in the loan
            period. When a borrower pre-pays the loan, huge interest cost is
            saved, specifically in case of personal loans, where the interest
            rates are quite high.{" "}
          </p>
          <p className="font-semibold mt-4">
            Why are borrowers charged in event of pre-payment?{" "}
          </p>
          <p>
            Lenders pre-determine a schedule in terms of the specified term of a
            loan, including the repayment schedule, and the interest
            expectation. An early prepayment disrupts this schedule and also
            means that the borrower has to pay lesser interest (since interest
            is calculated from the time the loan is disbursed, till it is
            repaid). Pre-payment charges are used as a client retention tool to
            discourage borrowers to move to other lenders, who may offer better
            interest for transferring the outstanding amount. It puts a
            limitation to the number of choices a customer can have due to
            market competition. To compensate for such loss, pre-payment charges
            exist.{" "}
          </p>
          <p className="font-semibold mt-4">
            What is the rate at which pre-payment charges are imposed?{" "}
          </p>
          <p>
            The rate is determined by the opportunity cost foregone due to
            pre-payment/foreclosure. The future cash flows are discounted at a
            relatively lower rate and accordingly imposed. The rate differs from
            bank to bank depending on their relevant factors and policies. For
            example: several banks charge early repayment penalties up to 2-3%
            of the principal amount outstanding.{" "}
          </p>
          <p className="font-semibold mt-4">
            How do banks benefit from the pre-payment penalties?{" "}
          </p>
          <p>
            The prepayment penalty is not charged with the motive to generate
            revenue, but to recover costs incurred due to mismatch in assets and
            liabilities. It is believed that when long-term loans are offered to
            borrowers, lending facility raises long-term deposits to match their
            assets and liabilities on their balance sheet. So when the loans are
            pre-paid with respect to their scheduled payments, lenders continue
            to have long-term deposits on their books, leading to a mismatch{" "}
          </p>
          <p className="font-semibold mt-4">
            What are the other factors that need to be kept in mind for
            pre-payment or foreclosure of loan?{" "}
          </p>
          <p>
            The applicable rate at which penalty shall be charged is a major
            factor as it should not result in higher cost to the borrower. Other
            factors include the process of undergoing pre-payment/foreclosure,
            lock-in period associated with the option, documentation etc.{" "}
          </p>
          <p className="font-semibold mt-4">What has been clarified?</p>
          <p>
            Earlier, the FPC provided that NBFCs shall not charge foreclosure
            charges/prepayment penalties from individuals on floating rate term
            loans. The clarification that has been provided by the RBI is that
            the foreclosure charges/prepayment penalties shall not be charged
            floating rate term loans, provided to individuals for purposes other
            than business i.e. personal purposes loans Applicability
          </p>
          <p className="font-semibold mt-4">
            On whom will this restriction be applicable?{" "}
          </p>
          <p>
            The change shall be applicable to all kinds of NBFCs, including
            systemically important as well as non-systemically important NBFCs
            who are into business of lending to individuals. However, NBFCs
            engaged in lending to non-individuals only are not required to
            comply with this requirement.{" "}
          </p>
          <p className="font-semibold mt-4">
            What kinds of loans will be covered?{" "}
          </p>
          <p>
            All floating rate term loans provided to individuals for purposes
            other than business shall be covered under the said restriction.
          </p>
          <p className="font-semibold mt-4">
            How will the lender define that loan is for purposes other than
            business?{" "}
          </p>
          <p>
            Before extending loans, documentation and background checks are
            performed. This process includes specification of the purpose for
            which the loan is taken. This gives a clear picture of the nature of
            the agreement and helps distinguish between business purpose and
            personal purposes.{" "}
          </p>
          <p className="font-semibold mt-4">
            Why is this restriction on floating rate term loans only and not on
            fixed rate terms loans?{" "}
          </p>
          <p>
            Fixed rate loans involve no fluctuations in interest rates in the
            entire loan term. Thus in case of pre-payment, the interest foregone
            can be computed and realised to evaluate pre-payment penalties to be
            imposed. While floating rate loans involve fluctuations based on the
            underlying benchmark and thus interest foregone cannot be estimated.
            There lies no confirmation of the lender being in the loss position.
            There is no way to realise interest rate sulking or hiking. Thus
            there is no basis on which overall loss might be estimated. In
            response to this situation, restrictions are on floating rate term
            loans and not on fixed rate term loans.{" "}
          </p>
          <p className="font-semibold mt-4">
            Are there any other entities under similar restriction?{" "}
          </p>
          <p>
            RBI has put restrictions, similar to this, on banks and Housing
            Finance Companies as well. Banks are not permitted to charge
            foreclosure charges / pre-payment penalties on home loans / all
            floating rate term loans, for purposes other than business,
            sanctioned to individual borrowers. HFCs are not permitted to charge
            foreclosure charges/ pre-payment penalties in case of foreclosure of
            floating interest rate housing loans or housing loans on fixed
            interest rate basis which are pre-closed by the borrowers out of
            their own sources.{" "}
          </p>
          <p className="font-semibold mt-4">
            When does this clarification come to effect?{" "}
          </p>
          <p>
            It is noteworthy that this is a clarification (and not a separate
            provision) issued by the RBI in respect of a provision which is
            already a part of RBI Master Directions for NBFCs. Therefore, this
            clarification is deemed to be in effect from the date the
            corresponding provision was issued by the RBI by way of a
            notification[3] i.e. August 01, 2014.{" "}
          </p>
          <p className="font-semibold mt-4">
            Implication What is the borrower's perspective?{" "}
          </p>
          <p>
            Borrower’s may choose to pre-pay due to their personal
            obligations/burden, or if they obtain their funds which were earlier
            stuck, or by borrowing from a cheaper source to repay. This waive
            off of penalty charges, might be a sign of relief to them as they
            would get out of the obligation of an existing loan arrangement by
            paying off early and save the compounding interests and explore from
            the other options available in the market.{" "}
          </p>
          <p className="font-semibold mt-4">
            What will happen after such clarification?{" "}
          </p>
          <p>
            Prior to this clarification, the provision seemed to be providing a
            safe shelter to individual borrowers where they could foreclose or
            pre-pay any loan taken by them. Sometimes, the borrowers misused
            this facility by availing funds at a lower cost from some other
            lender to pre-pay the loans of higher interest rate. This resulted
            in disruptions in the forecasts of lenders, sometimes also resulting
            in loss to the lender. This clarification limits the benefit of
            pre-payment to loans of personal nature only which are not availed
            very frequently by a borrower and are generally prepaid when
            borrowers have genuine savings or capital inflows.{" "}
          </p>
        </Container>
      </section>
    </>
  );
}

import Container from "components/Container";

export default function TermsConditions() {
  return (
    <>
      <section className="my-8 inner-content">
        <Container>
          <h1 className="font-bold text-2xl mb-4">Terms & Conditions</h1>
          <ul className="list-decimal pl-6 text-md font-bold space-y-4">
            <li className="space-y-3">
              <h3 className="font-bold text-md">
                VISITORS TO THIS WEB SITE ARE BOUND BY THE FOLLOWING TERMS AND
                CONDITIONS.
              </h3>
              <p>
                ('TERMS') PLEASE READ THIS DOCUMENT CAREFULLY BEFORE CONTINUING
                TO USE THIS SITE. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS,
                PLEASE DO NOT USE THIS SITE. IF YOU HAVE ANY QUERIES ABOUT THESE
                TERMS, PLEASE CONTACT service.contact@gllfl.com
              </p>
              <p>
                Disclaimer of Warranties, Inaccuracies or Errors | Golden Legand
                Leasing & Finance Disclaimer | Availability | Third Party
                Interaction and Links to Third Party Sites | Copyrights |
                Trademarks | General Terms and Conditions | Applicable Law and
                Jurisdiction | Disclaimer of Warranties, Inaccuracies or Errors
              </p>
              <p>
                Although GLLFL tries to ensure that all information and
                recommendations, whether in relation to the products, services,
                offerings or otherwise (hereinafter 'Information') provided as
                part of this website is correct at the time of its inclusion on
                the web site, GLLFL does not guarantee the accuracy of the
                Information. GLLFL makes no representations or warranties as to
                the completeness or accuracy of the Information.
              </p>
              <p>
                GLLFL makes no commitment to update or correct any Information
                that appears on the Internet or on this web site.
              </p>
              <p>
                Information is supplied upon the condition that the persons
                receiving the same will make their own determination as to its
                suitability for their purposes prior to use or in connection
                with the making of any decision. No Information at this web site
                shall constitute an invitation to invest in GLLFL or any of its
                affiliates. Any use of this website or the Information it
                contains is at your own risk. Neither GLLFL and GLLFL
                affiliates, nor their officers, employees or agents shall be
                liable for any loss, damage or expense arising out of any access
                to, use of, or GLLFL upon, this website or the Information, or
                any website linked to this website.
              </p>
              <p>
                Nothing contained herein is to be construed as a recommendation
                to use any product, process, equipment or formulation, in
                conflict with any patent, or otherwise and GLLFL makes no
                representation or warranty, express or implied that, the use
                thereof will not infringe any patent, or otherwise.
              </p>
              <p>
                THIS WEBSITE IS PROVIDED TO YOU ON AN “AS IS” AND “WHERE-IS”
                BASIS, WITHOUT ANY WARRANTY. GLLFL, FOR ITSELF AND ANY THIRD
                PARTY PROVIDING MATERIALS, SERVICES, OR CONTENT TO THIS WEBSITE,
                MAKES NO REPRESENTATIONS OR WARRANTIES, EITHER EXPRESSED,
                IMPLIED, STATUTORY OR OTHERWISE OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS,
                WITH RESPECT TO THE WEBSITE, THE INFORMATION OR ANY PRODUCTS OR
                SERVICES TO WHICH THE INFORMATION REFERS. GLLFL WILL NOT BE
                LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES OF ANY KIND,
                INCLUDING BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING FROM OR CONNECTED
                WITH THE SITE, INCLUDING BUT NOT LIMITED TO, YOUR USE OF THIS
                SITE OR YOUR INABILITY TO USE THE SITE, EVEN IF GLLFL HAS
                PREVIOUSLY BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">GLLFL Disclaimer</h3>
              <p>
                GLLFL has its own separate identity but in this web site we may
                sometimes use 'Mahindra & Mahindra Group Company', 'we' or 'us'
                when we refer to GLLFL company in general or where no useful
                purpose is served by identifying any particular GLLFL company
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Availability</h3>
              <p>
                GLLFL controls and operates this web site from India and makes
                no representation that the materials are appropriate or will be
                available for use in other locations. If you use this web site
                from outside India, you are entirely responsible for compliance
                with all applicable local laws.
              </p>
              <p>
                In case GLLFL has or develops more than one website offering
                products, services, content and various other functionalities
                (collectively the 'Services') to specific regions worldwide. The
                Services offered in one region may differ from those in other
                regions due to availability, local or regional laws, shipment
                and other considerations. GLLFL does not make any warranty or
                representation that a user in one region may obtain the Services
                from the GLLFL site in another region and GLLFL may cancel a
                user's order or redirect a user to the site for that user's
                region if a user attempts to order Services offered on a site in
                another region.
              </p>
              <p>
                Information that GLLFL publishes on the World Wide Web may
                contain references or cross references to GLLFL products,
                programs and services that are not announced or available in
                your country. Such references do not imply that GLLFL intends to
                announce such products, programs or services in your country.
                Consult your local GLLFL business contact for information
                regarding the products, programs and services that may be
                available to you.
              </p>
              <p>
                Nothing contained herein is to be construed as a recommendation
                to use any product, process, equipment or formulation, in
                conflict with any patent, or otherwise and GLLFL makes no
                representation or warranty, express or implied that, the use
                thereof will not infringe any patent, or otherwise.
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Copyrights</h3>
              <p>
                This web site contains material, including text, graphics and
                sound, which is protected by copyright and/or other intellectual
                property rights. All copyright and other intellectual property
                rights in this material are either owned by GLLFL or have been
                licensed to GLLFL by the owner(s) of those rights so that it can
                use this material as part of this web site. GLLFL retains
                copyright on all Information, including text, graphics and sound
                and all trademarks displayed on this web site are owned by GLLFL
                and used under license by GLLFL affiliates.
              </p>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">You may:</h3>
              <p>
                use and display the materials only on your personal computer
                only for personal use. GLLFL grants you a limited, personal,
                non-exclusive and non-transferable license only for such use.
              </p>
              <p>
                print copies of the information on this site for your personal
                use and store the files on your computer for personal use.
              </p>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">You may not:</h3>
              <p>
                copy (whether by printing onto paper, storing on disk,
                downloading or in any other way), distribute (including
                distributing copies), download, display, perform, reproduce,
                distribute, modify, edit, alter, enhance, broadcast or tamper
                with in any way or otherwise use any material contained in the
                web site except as set out under “You may”. These restrictions
                apply in relation to all or part of the material on the web
                site.
              </p>
              <p>
                copy and distribute this information on any other server, or
                modify or re-use text or graphics on this system or another
                system. No reproduction of any part of the web site may be sold
                or distributed for commercial gain nor shall it be modified or
                incorporated in any other work, publication or web site, whether
                in hard copy or electronic format, including postings to any
                other web site.
              </p>
              <p>
                remove any copyright, trade mark or other intellectual property
                notices contained in the original material from any material
                copied or printed off from the web site; link to this web site;
                without our express written consent.
              </p>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">Trademarks</h3>
              <p>
                The GLLFLTM and all products and logos denoted with TM are
                trademarks or registered trademarks of GLLFL or its parent.
                GLLFL trademarks may not be used in connection with any product
                or service that is not a GLLFL product or service.
              </p>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">
                General Terms and Conditions
              </h3>
              <p>
                GLLFL does not routinely monitor your postings to the web site
                but reserves the right to do so. However, in our efforts to
                promote good citizenship within the Internet community, if GLLFL
                becomes aware of inappropriate use of the web site or any of its
                Services, GLLFL will respond in any way that, in its sole
                discretion, GLLFL deems appropriate. You acknowledge that GLLFL
                will have the right to report to law enforcement authorities any
                actions that may be considered illegal, as well as any
                information it receives of such illegal conduct. When requested,
                GLLFL will co-operate fully with law enforcement agencies in any
                investigation of alleged illegal activity on the Internet.
              </p>
              <p>
                Submissions and unauthorised use of any materials contained on
                this web site may violate copyright laws, trademark laws, the
                laws of privacy and publicity, certain communications statutes
                and regulations and other applicable laws and regulations. You
                alone are responsible for your actions or the actions of any
                person using your user name and/or password. As such, you shall
                indemnify and hold GLLFL and its officers, directors, employees,
                affiliates, agents, licensors, and business partners harmless
                from and against any and all loss, costs, damages, liabilities,
                and expenses (including attorneys' fees) incurred in relation
                to, arising from, or for the purpose of avoiding, any claim or
                demand from a third party that your use of the web site or the
                use of the web site by any person using your user name and/or
                password (including without limitation your participation in the
                posting areas or your Submissions) violates any applicable law
                or regulation, or the rights of any third party.
              </p>
              <p>
                GLLFL reserves the right to terminate access to this web site at
                any time and without notice. Further this limited license
                terminates automatically, without notice to you, if you breach
                any of these Terms. Upon termination, you must immediately
                destroy any downloaded and printed materials. GLLFL may change
                the terms and conditions and disclaimer set out above from time
                to time. By browsing this web site you are accepting that you
                are bound by the current terms and conditions and disclaimer and
                so you should check these each time you revisit the web site.
              </p>
              <p>
                GLLFL may change the format and content of this web site at any
                time.
              </p>
              <p>
                GLLFL may suspend the operation of this web site for support or
                maintenance work, in order to update the content or for any
                other reason.
              </p>
              <p>
                Personal details provided to GLLFL through this web site will
                only be used in accordance with our privacy policy. Please read
                this carefully before going on. By providing your personal
                details to us you are consenting to its use in accordance with
                our privacy policy.
              </p>
              <p>
                If you have a question or complaint, please contact us at
                service.mmfsl@mahindra.com GLLFL reserves all other rights.
              </p>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">
                Applicable Law and Jurisdiction
              </h3>
              <p>
                These terms and conditions are governed by and to be interpreted
                in accordance with laws of India , without regard to the choice
                or conflicts of law provisions of any jurisdiction. You agree,
                in the event of any dispute arising in relation to these terms
                and conditions or any dispute arising in relation to the web
                site whether in contract or tort or otherwise, to submit to the
                jurisdiction of the courts located at Mumbai, India for the
                resolution of all such disputes.
              </p>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
}

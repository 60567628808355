import { useEffect, useState } from "react";
import Accordion from "./Accordion";
import NonAccordion from "./NonAccordion";
import { InversterDoc, TabsProps } from "../helpers/types";
import Container from "./Container";
import {
  extractSubtitles,
  groupReportsByFiscalYear,
} from "helpers/dataFormating";

const TabPanel = ({ tabsData }: TabsProps) => {
  const [data, setData] = useState<InversterDoc[]>([]);
  const [activeButton, setActiveButton] = useState<string>("");
  const [isButton, setIsButton] = useState<boolean>(false);

  // console.log("activeButton:", activeButton);

  // Function to handle button click
  const handleClick = (bttName: string) => {
    setActiveButton(bttName); // Set the active button
    const filtered = tabsData.filter((item) => item.subtitle === bttName);
    setData(filtered);

    bttName === "Reports" || bttName === "Quarterly Results"
      ? setIsButton(true)
      : setIsButton(false);
  };

  // Initialize the active button and data on mount
  useEffect(() => {
    const subtitles = extractSubtitles(tabsData);
    if (subtitles && subtitles.length > 0) {
      setActiveButton(subtitles[0]); // Set the first subtitle as the default active button
      handleClick(subtitles[0]); // Load data for the default button
    }
  }, [tabsData]);

  return (
    <Container>
      <div className="my-4">
        <div className="text-green-700 text-lg font-bold mb-6 flex gap-4">
          {extractSubtitles(tabsData)?.map((item, index) => (
            <button
              key={index}
              onClick={() => handleClick(item)}
              className={`cursor-pointer px-4 py-2 hover:bg-primary hover:text-white hover:rounded-lg transition duration-300 ease-in-out ${
                activeButton === item
                  ? "bg-primary text-white rounded-lg shadow-lg" // Active button styles
                  : "bg-slate-100 text-gray-600 rounded-lg" // Inactive button styles
              }`}
            >
              {item}
            </button>
          ))}
        </div>
      </div>

      {/* Conditional rendering based on isAccordion */}
      {isButton ? (
        <Accordion
          items={groupReportsByFiscalYear(data)}
          initialOpenIndex={0}
        />
      ) : (
        <NonAccordion data={data} />
      )}
    </Container>
  );
};

export default TabPanel;

// src/redux/store.ts
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import documentSlice from "./documentSlice";
import contactUsSlice from "./contactUsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    document: documentSlice,
    contact: contactUsSlice,
  },
});

// Define types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import axios from "axios";
import {
  CareerDropdownItems,
  ClientFormDatas,
  JobData,
  JobFormDatas,
  JobItems,
} from "./types";
const { REACT_APP_BASE_URL } = process.env;

export const getJob = async (parsedJobId: number | null): Promise<JobData> => {
  try {
    const response = await axios.get<JobData>(
      `${REACT_APP_BASE_URL}/jobs/${parsedJobId}`
    );
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the job data!", error);
    throw error;
  }
};

export const submitApplication = async (
  formData: JobFormDatas,
  jobId: string | number | null
): Promise<string> => {
  const form = new FormData();
  form.append("name", formData.name);
  form.append("email", formData.email);
  form.append("phone", formData.phone);
  form.append("coverLetter", formData.coverLetter);
  if (formData.resume) {
    form.append("resume", formData.resume);
  }
  form.append("jobId", jobId?.toString() || "");

  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/apply`, form);
    return response.data.message;
  } catch (error: any) {
    console.error("There was an error submitting the application:", error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error("There was an error submitting the application.");
    }
  }
};

export const fetchJobs = async (
  searchText: string,
  selectedFilters: { category: string; type: string; locations: string }
): Promise<JobItems[]> => {
  try {
    const params: any = {};
    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key]) {
        params[key] = selectedFilters[key];
      }
    });
    if (searchText) {
      params.search = searchText;
    }

    const response = await axios.get<JobItems[]>(`${REACT_APP_BASE_URL}/jobs`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the job data!", error);
    throw error;
  }
};

export const fetchDropdowns = async (): Promise<CareerDropdownItems[]> => {
  try {
    const response = await axios.get<CareerDropdownItems[]>(
      `${REACT_APP_BASE_URL}/dropdowns`
    );
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the dropdown data!", error);
    throw error;
  }
};

export const submitContactForm = async (
  formData: ClientFormDatas
): Promise<string> => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/contact`,
      formData
    );
    return response.data.message;
  } catch (error) {
    console.error("There was an error submitting the form:", error);
    throw error;
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocumentApi, postDocumentsApi } from "utils/apis";

interface DocumentState {
  data: any | null;
  loading: boolean;
  error: String | null;
  status: Number;
}

const initialState: DocumentState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
};

// Create async thunk for login
export const getDocumentData = createAsyncThunk(
  "getDocumentData",
  async (body: any) => {
    const response = await getDocumentApi(body);

    return response;
  }
);

export const postDocuments = createAsyncThunk(
  "postDocumentData",
  async (body: any) => {
    const response = await postDocumentsApi(body);
    return response;
  }
);

const documentSlice = createSlice({
  name: "documentStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentData.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getDocumentData.fulfilled, (state, action) => {
        state.status = 2;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(getDocumentData.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Documents failed";
      });

    builder
      .addCase(postDocuments.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(postDocuments.fulfilled, (state, action) => {
        state.status = 1;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(postDocuments.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Documents failed";
      });
  },
});
export default documentSlice.reducer;

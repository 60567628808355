import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postContactsApi } from "utils/apis";

interface ContactState {
  data: any | null;
  loading: boolean;
  error: String | null;
  status: Number;
}

const initialState: ContactState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
};

export const postContact = createAsyncThunk(
  "postContactData",
  async (body: any) => {
    let bodyData = body.formData;
    const response = await postContactsApi(bodyData);
    return response;
  }
);

const contactUsSlice = createSlice({
  name: "contactStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postContact.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(postContact.fulfilled, (state, action) => {
        state.status = 1;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(postContact.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Contact Us failed";
      });
  },
});
export default contactUsSlice.reducer;

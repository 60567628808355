// api.ts
import axios, { AxiosRequestConfig } from "axios";
import BASE_URLS from "../config";

const createApiInstance = (service: keyof typeof BASE_URLS) => {
  const baseURL = BASE_URLS[service];
  console.log("Base URL for", service, ":", baseURL); // This will log the base URL being used

  const api = axios.create({
    baseURL: baseURL, // Dynamically use the correct base URL
  });
  return api;
};

export const callApi = async (
  service: keyof typeof BASE_URLS,
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: any
) => {
  try {
    const api = createApiInstance(service);
    let data = body ? body.body : null;
    console.log("data12345", api, service);
    if (data) {
      const config: AxiosRequestConfig = {
        method,
        url: endpoint,
        data,
      };
      const response = await api(config);
      console.log("body", response.data);
      return response.data;
    } else {
      console.log("data111", endpoint);
      const config: AxiosRequestConfig = {
        method,
        url: endpoint,
      };

      const response = await api(config);
      console.log("body", response.data);
      return response.data;
    }
  } catch (error: any) {
    return error.response?.data;
  }
};

export const callApiWithParams = async (
  service: keyof typeof BASE_URLS,
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: any
) => {
  try {
    const api = createApiInstance(service);
    const config: AxiosRequestConfig = {
      method,
      url: endpoint + "/" + `${body.body.investId}`,
      data: body ? body.body : undefined,
    };
    const response = await api(config);
    console.log("body", response.data);
    return response.data;
  } catch (error: any) {
    console.log(error.response.data.message);
    return error.response?.data;
  }
};

export const loginApi = async (body: any) => {
  return await callApi("service1", "/login", "POST", {
    body,
  });
};
export const getDocumentApi = async (body: any) => {
  return await callApi("service1", `/getByInvestId/${body.investId}`, "GET", {
    body,
  });
};

export const postDocumentsApi = async (body: string) => {
  return await callApi("service1", "/create", "POST", { body });
};

export const postContactsApi = async (body: string) => {
  console.log("sunder", body);
  return await callApi("service1", "/contactus/create", "POST", { body });
};
// fetching data announcements
export const AnnouncementApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("AnnouncementApiFetch api Errror:", error);
  }
};

// fetching data announcements
export const CorporateGovernanceReportApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("CorporateGovernanceReportApiFetch api Errror:", error);
  }
};

// fetching data Fiancial Result
export const FinancialResultsApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("FinancialResultsApiFetch api Errror:", error);
  }
};

// fetching data Annual Return
export const AnnualReturnApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("AnnualReturnApiFetch api Errror:", error);
  }
};

// fetching data Annual Return
export const AnnualSecretarialComplianceApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("AnnualSecretarialComplianceApiFetch api Errror:", error);
  }
};

// fetching data CodeofConductPolicies
export const CodeofConductPoliciesApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("CodeofConductPoliciesApiFetch api Errror:", error);
  }
};

// fetching data CompositionCommittee
export const CompositionCommitteeApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("CompositionCommitteeApiFetch api Errror:", error);
  }
};

// fetching data InvestorComplaints
export const InvestorComplaintsApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("InvestorComplaintsApiFetch api Errror:", error);
  }
};

// fetching data ReconciliationofShareCapitalApiFetch
export const ReconciliationofShareCapitalApiFetch = async (
  investId: number
) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("ReconciliationofShareCapitalApiFetch api Errror:", error);
  }
};

// fetching data ShareholdingPatternApiFetch
export const ShareholdingPatternApiFetch = async (investId: number) => {
  try {
    const result = await callApi(
      "service1",
      `/getByinvestId/${investId}`,
      "GET"
    );
    return result;
  } catch (error) {
    console.log("ShareholdingPatternApiFetch api Errror:", error);
  }
};

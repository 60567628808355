import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import materialityEventsData from "../utils/data/materialityEventsData";

export default function MaterialityEvents() {
  return (
    <>
      <BreadCrumb pageName="MaterialityEvents" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Materiality of Events" />
        {/* <TabPanel tabsData={materialityEventsData} /> */}
      </div>
    </>
  );
}

import Container from "components/Container";

export default function WebsiteContentArchivalPolicy() {
  return (
    <>
      <section className="my-8 inner-content">
        <Container>
          <h1 className="font-bold text-2xl mb-4">
            Website Content Archival Policy
          </h1>
          <ul className="list-decimal pl-6 text-md font-bold space-y-4">
            <li className="space-y-3">
              <h3 className="font-bold text-md">PREAMBLE</h3>
              <p>
                Securities and Exchange Board of India has notified Securities
                and Exchange Board of India (Listing Obligation and Disclosure
                Requirements) Regulations, 2015. These regulations require every
                listed Company to give various disclosures of corporate actions,
                events, documents etc on the website of the Company. These
                disclosures made on the website can be archived as per the
                Policy.
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">SCOPE</h3>
              <p>
                This policy shall govern the disclosure and archival of such
                contents only which have been disclosed on the website of the
                Company pursuant to the compliance with the abovementioned
                Regulations and which are disclosed with stock exchanges. Other
                contents displayed / hosted on the website shall be out of
                purview of the Policy and can be archived / deleted as per the
                requirement of the content.
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">OBJECTIVES OF THE POLICY</h3>
              <p>
                The main objective of this policy is to ensure that all the
                documents, disclosures made to the stock exchanges pursuant to
                the Regulations are hosted on the website and transferred to the
                Archives folder of the Website of the Company after the
                completion of five years from the date of disclosure or event.
              </p>
              <p>The major objectives of the policy are:</p>
              <ul className="list-disc pl-6 font-normal">
                <li>
                  Identification of Information, documents, events, etc which
                  are required to be disclosed on the website of the Company.
                </li>
                <li>
                  Ensuring that all the information is disclosed at the relevant
                  link and there is unanimity in the same every time.
                </li>
                <li>
                  Ensure that all the information, documents, events disclosed
                  on the website of the Company, unless otherwise specified in
                  the Act, shall remain on the website for the five years from
                  the date of disclosure.
                </li>
                <li>
                  After completion of five years of disclosure of the respective
                  documents, shall be moved to the respective archives folder on
                  the website of the Company.
                </li>
                <li>
                  Documents which are required to be hosted on the website for a
                  particular period, shall be deleted after the due date,
                  therefore, it shall not be saved to archives folder.
                </li>
                <li>
                  Certain documents like Policies, Codes of Conduct and other
                  documents which are required to be continuously displayed on
                  the website, shall not be archived.
                </li>
              </ul>
              <p className="font-bold text-md underline underline-offset-4">
                DEFINITIONS
              </p>
              <ul className="list-disc pl-6 font-normal">
                <li>
                  "Act" means the Companies Act, 2013, Rules framed thereunder
                  and any amendments thereto;
                </li>
                <li>
                  “Compliance Owner” shall mean any person responsible for all
                  statutory and legal compliances of a particular unit/
                  division/ function of the Company.”
                </li>
                <li>
                  “Regulations” means Securities and Exchange Board of India
                  (Listing Obligations and Disclosure Requirements) Regulations,
                  2015
                </li>
                <li>
                  “Chief Compliance Officer” shall mean any person responsible
                  for all statutory and legal compliances of the Company and who
                  shall be the Chief Risk Officer/ President-Risk of the
                  Company.”
                </li>
                <li>
                  “Company”, “This Company”, “The Company”, “Company” wherever
                  occur in the policy shall mean “Golden Legand Leasing &
                  Finance Ltd."
                </li>
                <li>
                  "Board of Director" or "Board", means the collective body of
                  the Directors of the company;
                </li>
                <li>
                  “Policy” or “This Policy” means, “Website Content Archival
                  Policy.”
                </li>
                <li>
                  "Risk Management Committee" means the Committee of Directors
                  and Senior Managerial Personnel of the Company formulated for
                  monitoring and reviewing the risk management and Legal
                  Compliance framework of the Company.
                </li>
              </ul>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">INTERPRETATION</h3>
              <p>
                Terms that have not been defined in this Policy shall have the
                same meaning assigned to them in the Companies Act, 2013,
                Securities and Exchange Board of India (Listing Obligation and
                Disclosure Requirements) Regulations, 2015, Listing Agreement
                and/or any other SEBI Regulation(s) as amended from time to
                time.
              </p>
              <p>
                In case of any dispute or difference upon the
                meaning/interpretation of any word or provision in this Policy,
                the same shall be referred to the Risk Management Committee to
                decide thereupon.
              </p>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">GUIDELINES</h3>
              <p>
                The Company being listed on NSE & BSE, regularly sends various
                information, financial statements, notices and other
                disclosures, as required under listing agreement to Stock
                Exchanges where the equity shares of the Company are listed. As
                required under the Act and the Listing agreement the same are
                also displayed and hosted on the website of the Company.
              </p>
              <p>
                Regulation 46 of Regulations requires the listed Companies to
                maintain a functional website containing basic information about
                the Company and to update the same within two days of any change
                taking place therein.
              </p>
              <p>
                Regulation 30 requires the Listed Companies to disclose on its
                website all such events or information which has been disclosed
                to stock exchange(s) under this regulation, and such disclosures
                shall be hosted on the website of the Company for a minimum
                period of five years and thereafter as per the archival policy
                of the Company, as disclosed on its website.
              </p>
            </li>

            <li className="space-y-3">
              <h3 className="font-bold text-md">POLICY</h3>
              <p>
                The required documents, information, disclosures, notices,
                policies as provided under the Regulations and the Act, which
                are required to be disclosed on the website shall be uploaded on
                the Website of the Company. The website of the Company shall be
                reviewed on regular intervals for ensuring that all the
                abovementioned disclosures are available on the website of the
                Company as required.
              </p>
              <p>
                These disclosures, unless otherwise mentioned in the Act itself,
                shall be hosted on the website for the specified period and
                thereafter shall be moved/transferred to Archives folders under
                the respective heads / sub-folders, in a way so that these can
                be searched easily as and when required by any person. The
                documents/disclosures shall be kept in the archive folders till
                such time as may be required by law.
              </p>
              <ul className="list-disc pl-6 font-normal">
                <h4 className="font-semibold text-md">
                  The following information shall be uploaded on the website and
                  shall be updated within two working days from the date of
                  occurrence of change:
                </h4>
                <li>Details of Company business.</li>
                <li>
                  Terms and conditions of appointment of Independent Directors.
                </li>
                <li>Composition of various committees of Board of Directors</li>
                <li>
                  Code of conduct of Board of Directors and Senior Management
                  Personnel
                </li>
                <li>
                  Details of establishment of Vigil Mechanism/ Whistle Blower
                  policy
                </li>
                <li>
                  Criteria of making payments to non-executive directors , if
                  the same has not been disclosed in annual report
                </li>
                <li>Policy on dealing with related party transactions</li>
                <li>Policy for determining 'material' subsidiaries;</li>
                <li>
                  Details of familiarization programmes imparted to Independent
                  Directors.
                </li>
                <li>
                  Email address for grievance redressal and other relevant
                  details.
                </li>
                <li>
                  Contact information of the designated officials who are
                  responsible for assisting and handling investor grievances;
                </li>
                <li>Financial information including:</li>
                <li>
                  notice of meeting of the board of directors where financial
                  results shall be discussed;
                </li>
                <li>
                  financial results, on conclusion of the meeting of the Board
                  of Directors where the financial results were approved;
                </li>
                <li>
                  Complete copy of the annual report including balance sheet,
                  profit and loss account, directors report, corporate
                  governance report etc;
                </li>
                <li>Shareholding pattern.</li>
                <li>
                  Details of agreements entered into with the media companies
                  and/or their associates, etc;
                </li>
                <li>
                  Schedule of analyst or institutional investor meet and
                  presentations made to analysts or institutional investors
                  simultaneously with submission to stock exchange.
                </li>
                <li>
                  New name and the old name of the Company, for a continuous
                  period of one year, from the date of the last name change, if
                  applicable.
                </li>
                <li>
                  Notice of Board meeting to consider financial results
                  published in newspapers.
                </li>
                <li>Financial Results published in newspapers.</li>
                <li>
                  Statement of deviation/ variation in use of proceeds from
                  public issue, rights issue, preferential issue etc.
                </li>
                <li>
                  Notices given to shareholders by newspaper advertisement.
                </li>
              </ul>

              <ul className="list-disc pl-6 font-normal">
                <h4 className="font-semibold text-md">
                  The following information shall be uploaded on the website as
                  soon as it is disclosed to Stock Exchanges and shall be
                  achieved after a period of five years:
                </h4>
                <li>
                  Acquisition(s) (including agreement to acquire), Scheme of
                  Arrangement (amalgamation/ merger/ demerger/restructuring), or
                  sale or disposal of any unit(s), division(s) or subsidiary of
                  the Company or any other restructuring.
                </li>
                <li>
                  Issuance or forfeiture of securities, split or consolidation
                  of shares, buyback of securities, any restriction on
                  transferability of securities or alteration in terms or
                  structure of existing securities including forfeiture, reissue
                  of forfeited securities, alteration of calls, redemption of
                  securities etc.
                </li>
                <li>Revision in Rating(s).</li>
                <li>Outcome of Meetings of the Board of Directors.</li>
                <li>
                  Dividends and/or cash bonuses recommended or declared or the
                  decision to pass any dividend and the date on which dividend
                  shall be paid/dispatched.
                </li>
                <li>Any cancellation of dividend with reasons thereof.</li>
                <li>Board Decision on Buyback of securities.</li>
                <li>
                  Board Decision of fund raising proposed to be undertaken.
                </li>
                <li>
                  Increase in capital by issue of bonus shares through
                  capitalization including the date on which such bonus shares
                  shall be credited/dispatched.
                </li>
                <li>
                  Reissue of forfeited shares or securities, or the issue of
                  shares or securities held in reserve for future issue or the
                  creation in any form or manner of new shares or securities or
                  any other rights, privileges or benefits to subscribe to;
                </li>
                <li>Financial results;</li>

                <li>
                  Board decision on voluntary delisting from stock exchange(s).
                </li>
                <li>
                  Agreements (viz. shareholder agreement(s), joint venture
                  agreement(s), family settlement agreement(s) (to the extent
                  that it impacts management and control of the Company),
                  agreement(s)/treaty(ies)/contract(s) with media companies)
                  which are binding and not in normal course of business,
                  revision(s) or amendment(s) and termination(s) thereof.
                </li>
                <li>
                  Fraud/defaults by promoter or key managerial personnel or by
                  Company or arrest of key managerial personnel or promoter.
                </li>
                <li>
                  Change in Directors, key managerial personnel (Managing
                  Director, Chief Executive Officer, Chief Financial Officer,
                  Company Secretary etc.), Auditor and Compliance Officer.
                </li>
                <li>Appointment or discontinuation of share transfer agent.</li>
                <li>Corporate debt restructuring.</li>
                <li>One time settlement with a bank.</li>
                <li>
                  Reference to BIFR and winding-up petition filed by any party /
                  creditors.
                </li>
                <li>
                  Issuance of Notices, call letters, resolutions and circulars
                  sent to shareholders, debenture holders or creditors or any
                  class of them or advertised in the media by the Company.
                </li>
                <li>
                  Proceedings of Annual and extraordinary general meetings of
                  the Company.
                </li>
                <li>
                  Amendments to memorandum and articles of association of
                  Company, in brief.
                </li>
                <li>
                  Schedule of Analyst or institutional investor meet and
                  presentations on financial results made by the Company to
                  analysts or institutional investors;
                </li>
                <li>
                  Commencement or any postponement in the date of commencement
                  of commercial production or commercial operations of any
                  unit/division.
                </li>
                <li>
                  Material change in the general character or nature of business
                  brought about by arrangements for strategic, technical,
                  manufacturing, or marketing tie-up, adoption of new lines of
                  business or closure of operations of any unit/division
                  (entirety or piecemeal).
                </li>
                <li>Material capacity addition or product launch.</li>
                <li>
                  Awarding, bagging/ receiving, amendment or termination of
                  awarded/bagged orders/ contracts not in the normal course of
                  business.
                </li>
                <li>
                  Material agreements (viz. loan agreement(s) (as a borrower) or
                  any other agreement(s) which are binding and not in normal
                  course of business) and revision(s) or amendment(s) or
                  termination(s) thereof.
                </li>
                <li>
                  Major disruption of operations of any one or more units or
                  division of the Company due to natural calamity (earthquake,
                  flood, fire etc.), force majeure or events such as strikes,
                  lockouts etc.
                </li>
                <li>
                  Material effect(s) arising out of change in the regulatory
                  framework applicable to the Company.
                </li>
                <li>
                  Material litigation(s) / dispute(s) / regulatory action(s)
                  with impact.
                </li>
                <li>
                  Material fraud/defaults etc. by Directors (other than key
                  managerial personnel) or employees of Company.
                </li>
                <li>
                  Options to purchase securities including any ESOP/ESPS Scheme.
                </li>
                <li>
                  Giving of guarantees or indemnity or becoming a surety for any
                  third party.
                </li>
                <li>
                  Granting, withdrawal, surrender, cancellation or suspension of
                  key licenses or regulatory approvals.
                </li>
                <li>
                  Any other information/event viz. major development that is
                  likely to affect business, e.g. emergence of new technologies,
                  expiry of patents, any change of accounting policy that may
                  have a significant impact on the accounts, etc. and brief
                  details thereof and any other information which is exclusively
                  known to the Company which may be necessary to enable the
                  holders of securities of the Company to appraise its position
                  and to avoid the establishment of a false market in such
                  securities.
                </li>
                <li>
                  Any other disclosures of event/information as specified by the
                  Board from time to time.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">REVIEW</h3>
              <p>
                This policy shall be reviewed from time to time so that the
                policy remains complaint with applicable legal requirements. The
                Company Secretary will keep the policy updated as per applicable
                statutory guidelines.
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">DISCLOSURES</h3>
              <p>
                The policy shall be continuously hosted on the website of the
                Company after the approval of the Board of Directors and after
                every amendment/updation thereof.
              </p>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
}

import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import schemeofArrangementData from "../utils/data/schemeofArrangementData";

export default function SchemeofArrangement() {
  return (
    <>
      <BreadCrumb pageName="SchemeofArrangement" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Scheme of Arrangement" />
        {/* <TabPanel tabsData={schemeofArrangementData} /> */}
      </div>
    </>
  );
}

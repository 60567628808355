import React, { useState } from "react";
import BreadCrumb from "components/BreadCrumb";
import Container from "components/Container";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Investor List Data
const listItems = [
  { investId: 1, name: "Announcements", href: "/announcements" },
  {
    investId: 2,
    name: "Composition of Committee",
    href: "/composition-of-committee",
  },
  {
    investId: 3,
    name: "Corporate Governance Report",
    href: "/corporate-governance-report",
  },
  { investId: 4, name: "Materiality of Events", href: "/" },
  {
    investId: 5,
    name: "Annual Secretarial Compliance Report",
    href: "/annual-secretarial-compliance-report",
  },
  { investId: 6, name: "Financial Results", href: "/financial-results" },
  { investId: 7, name: "Shareholding Pattern", href: "/shareholding-pattern" },
  {
    investId: 8,
    name: "Code of Conduct & Policies",
    href: "/code-of-conduct-policies",
  },
  { investId: 9, name: "Annual Return", href: "/annual-return" },

  { investId: 10, name: "Scheme of Arrangement", href: "/" },
  { investId: 11, name: "Advice to Shareholders", href: "/" },
  { investId: 12, name: "Monitoring Agency Report", href: "/" },
  { investId: 13, name: "Unclaimed Dividend", href: "/" },
  { investId: 14, name: "Investor Complaints", href: "/investor-complaints" },
  { investId: 15, name: "Unclaimed Shares", href: "/" },
  { investId: 16, name: "Link to ODR", href: "/" },
  {
    investId: 17,
    name: "Reconciliation of Share Capital",
    href: "/reconciliation-of-Share-Capital",
  },
];

function InvestorListPage() {
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState(null);

  const handleItemClick = async (investId: number) => {
    try {
      const response = await axios.get(
        `http://192.168.5.55:3004/api/getById/${investId}`
      );
      setSelectedData(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }

    for (let i = 0; i < listItems.length; i++) {
      if (investId === listItems[i].investId) {
        navigate(listItems[i].href);
      }
    }
  };
  return (
    <>
      <div className="InvestorListing-Wrapper">
        <BreadCrumb pageName="Investors Corner List" />

        <Container>
          <div className="mb-8 px-8 gap-8">
            {/* Investor Corners Menu start */}
            <ul className="grid grid-cols-5 gap-x-6 gap-y-6 mb-4">
              {listItems.map((item) => (
                <li key={item.investId}>
                  <button
                    onClick={() => handleItemClick(item.investId)}
                    className="w-full border rounded-lg px-2 py-3 bg-gray-300 hover:bg-primary hover:text-white qtpdfBtn group flex items-center justify-center customShadow text-sm"
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>

            {/* Render selected data */}
            {selectedData && (
              <div className="mt-4 p-4 border rounded-lg bg-gray-100">
                <h2 className="text-lg font-semibold">Details</h2>
                <pre className="text-sm">
                  {JSON.stringify(selectedData, null, 2)}
                </pre>
              </div>
            )}
            {/* Investor Corners Menu end */}
          </div>
        </Container>
      </div>
    </>
  );
}

export default InvestorListPage;

import Container from "components/Container";

export default function RiskManagementPolicy() {
  return (
    <>
      <section className="my-8 inner-content">
        <Container>
          <h1 className="font-bold text-2xl mb-4">Risk Management Policy</h1>
          <ul className="list-decimal pl-6 text-md font-bold space-y-4">
            <li className="space-y-3">
              <h3 className="font-bold text-md">Introduction</h3>
              <p>
                In accordance with the Companies Act, 2013 and the Securities
                and Exchange Board of India (Listing Obligations and Disclosure
                Requirements) Regulations, 2015, the board of directors (the
                “Board”) of Golden Legand Leasing & Finance Ltd. (the “Company”)
                has adopted a policy on risk management (the “Risk Management
                Policy”).
              </p>
              <ul className="list-decimal pl-6">
                <li>
                  The Board of the Company and the audit committee of the
                  Company (the “Audit Committee”) shall periodically review the
                  risk management policy of the Company so that management
                  controls the risk through properly defined network.
                </li>
                <li>
                  The respective head of departments shall be responsible for
                  implementation of the risk management system as may be
                  applicable to their respective areas of functioning and report
                  to the Board and the Audit Committee as may be required.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Objective</h3>
              <p>
                The objective of the Risk Management Policy of the Company is to
                create and protect shareholder value by minimizing threats or
                losses, and identifying and maximizing opportunities. This Risk
                Management Policy is being applied in order to ensure that
                effective management of risks is an integral part of every
                employee’s job. These include:
              </p>
              <ul className="list-decimal pl-6">
                <li>
                  The objective of the Risk Management Policy of the Company is
                  to create and protect shareholder value by minimizing threats
                  or losses, and identifying and maximizing opportunities. This
                  Risk Management Policy is being applied in order to ensure
                  that effective management of risks is an integral part of
                  every employee’s job. These include:
                </li>
                <li>
                  Providing a framework that enables all activities to take
                  place in a consistent and controlled manner so that the
                  assets, cash flow, and business of the Company are not exposed
                  to any undue risks;
                </li>
                <li>
                  Making a process that will ensure informed decision making
                  with all consequential financial and non-financial
                  implications on the Company.
                </li>
                <li>
                  Improving decision making, planning and prioritization by
                  comprehensive and structured understanding of business
                  activities, volatility and threats;
                </li>
                <li>
                  Contributing towards more efficient use/ allocation of the
                  resources within the organization;
                </li>
                <li>Protecting and enhancing assets and company image;</li>
                <li>Reducing volatility in various areas of the business;</li>
                <li>
                  Developing and supporting people and knowledge base of the
                  organization; and
                </li>
                <li>Optimizing operational efficiency.</li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Risk Management</h3>
              <p>
                The Company shall lay down procedures to inform Board members
                about the risk assessment and minimization procedures.
              </p>
              <p>
                The Board, its audit committee and its management should
                collectively identify the risks impacting the Company's business
                and document their process of risk identification, risk
                minimization, risk optimization as a part of a risk management
                policy or strategy. The Board should also affirm and disclose in
                its report to members that it has put in place critical risk
                management framework across the company, which is overseen
                periodically by the Board. The disclosure should also include a
                statement of those elements of risk, that the Board feels, may
                threaten the existence of the company. It has therefore become
                imperative for the Company to prepare a comprehensive framework
                of risk management for assessment of risks and determine the
                responses to these risks so as to minimize their adverse impact
                on the organisation.
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Risk Identification</h3>
              <p>
                Risk identification is concerned with identifying and assessing
                the probable risks and their material effects on the business
                goals / objectives of the Company in the changing and dynamic
                environment, both internal as well as external.
              </p>
              <p>
                Risk identification and assessment includes the risks provided
                below. However, the list provided below is illustrative and not
                conclusive :
              </p>
              <ul className="list-decimal pl-6">
                <li>
                  Economic environment and market conditions: Economic slowdowns
                  or factors that affect the economic health of our clients and
                  the said industries may increase risk to our revenue growth.
                </li>
                <li>
                  Political environment: Any adverse change in the political
                  environment in the country would have an impact in growth
                  strategies of the company.
                </li>
                <li>
                  Approvals and clearances from any regulatory, statutory and
                  governmental authorities and includes environmental
                  clearances.
                </li>
                <li>
                  Raw Material Risk: One of the key risks faced by the Company
                  in today’s scenario is the wide and frequent fluctuation in
                  crude oil prices that affect the pricing of Light Liquid
                  Paraffin (LLP)- a key ingredient.
                </li>
                <li>
                  Commercial Risks: Change of power, Indenting approval process,
                  Bank guarantees clauses, payment terms, billing approval
                  process, taxes and duties etc.
                </li>
                <li>
                  Liquidity Risks: Financial solvency and liquidity
                  mismanagement gives rise to Organizational Risks, Inadequate
                  quality of technical and managerial resources, poor cost
                  control/working capital management etc.
                </li>
                <li>
                  Technical, quality or performance risk: Meeting performance
                  standards and time schedule etc.
                </li>
                <li>
                  Logistics risks: Accommodation of project personnel in remote
                  locations, transportation of material to difficult sites etc.
                </li>
                <li>
                  Dispute Resolution Improper legal clauses, Arbitration laws,
                  etc.
                </li>
                <li>
                  Financial reporting risks: Changing laws, regulations and
                  standards relating to accounting, corporate governance and
                  public disclosures are creating uncertainty for companies.
                  These new or changed laws, regulations and standards may lack
                  specificity and are subject to varying interpretations. Their
                  application in practice may evolve over time, as new guidance
                  is provided by regulatory and governing bodies. This could
                  result in continuing uncertainty regarding compliance matters
                  and higher costs of compliance as a result of ongoing
                  revisions to such corporate governance standards.
                </li>
                <li>
                  Foreign Exchange Risk – To ensure that there should not be any
                  unhedged foreign exchange exposure on account of Principal or
                  Interest – on Capital or Current Account.
                </li>
                <li>
                  Corporate Governance & Compliance – It has to ensure that
                  Company does not fall short of any formal or informal
                  requirement of Corporate Governance and all Regulation and
                  Laws of the country are fully complied with.
                </li>
                <li>
                  Ensure that all Statutory and Legal Dues are paid within
                  stipulated time to avoid non- compliance.
                </li>
                <li>
                  Risk of Corporate accounting fraud: Accounting fraud or
                  corporate accounting fraud are business scandals arising out
                  of misusing or misdirecting of funds, overstating revenues,
                  understating expenses etc.
                </li>
                <li>
                  Legal risk: Legal risk is the risk in which the Company is
                  exposed to legal action as the Company is governed by various
                  laws and the Company has to do its business within four walls
                  of law, where the Company is exposed to legal risk exposure.
                </li>
                <li>
                  Human resource management risk: Attrition of talented work
                  force both at technical and managerial level.
                </li>
                <li>
                  System capability, system reliability, data integrity risks,
                  coordinating and interfacing risks.
                </li>
                <li>
                  Disaster Risks: Natural risks like fire, floods, earthquakes,
                  etc.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Risk Evaluaiton</h3>
              <p>
                As an integral and unavoidable component of business, BCCL is
                committed to managing the risk in a proactive and effective
                manner. Though Risk cannot be eliminated, it can be:
              </p>
              <ul className="list-disc pl-6">
                <li>
                  Transferred to another party, who is willing to take risk, say
                  by buying an insurance policy or entering into a forward
                  contract;
                </li>
                <li>Reduced, by having good internal controls;</li>
                <li>Avoided, by not entering into risky businesses;</li>
                <li>
                  Retained, to either avoid the cost of trying to reduce risk or
                  in anticipation of higher profits by taking on more risk, and;
                </li>
                <li>
                  Shared, by following a middle path between retaining and
                  transferring risk.
                </li>
              </ul>
              <p>
                As a diversified enterprise, the company has always had a
                system- based approach to business risk management. Backed by
                strong internal control systems, the current risk management
                framework consists of the following elements:
              </p>
              <ul className="list-decimal pl-6">
                <li>
                  Clear and unambiguous identification of the roles and
                  responsibilities of various stakeholders in relation to risk
                  management. These role definitions inter alia are aimed at
                  ensuring formulation of appropriate risk management policies
                  and procedures, their effective implementation and independent
                  monitoring and reporting by Internal Audit.
                </li>
                <li>
                  Combination of best industry practices and divisionally -
                  evolved procedures brings robustness to the process of
                  ensuring business risks are effectively addressed.
                </li>
                <li>
                  Appropriate structures have been put in place to effectively
                  address the inherent risks in businesses.
                </li>
                <li>
                  A strong and independent Internal Audit Function at the
                  corporate level carries out risk focused audits across all
                  businesses, enabling identification of areas where risk
                  managements processes may need to be improved. The Audit
                  Committee of the board reviews internal Audit findings and
                  provides strategic guidance on internal controls.
                </li>
                <li>
                  Monitoring the internal control environment within the Company
                  and ensuring that Internal Audit recommendations are
                  effectively implemented.
                </li>
                <li>
                  The senior management of the Company periodically reviews the
                  risk management framework to maintain its contemporariness so
                  as to effectively address the emerging challenges in a dynamic
                  business environment.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Risk Minimisation</h3>
              <ul className="list-decimal pl-6">
                <li>
                  Economic Environment and Market conditions: Strategically, we
                  seek to maximise the potential Turnover and at the same time
                  securing additional volumes from existing clients on the basis
                  of our record of satisfactory performance in our earlier
                  dealings. The efforts to enhance quality of service/execution
                  and upgrading their performance parameters are aimed at
                  deriving optimum value from the existing client base. To
                  counter bidding pressures caused by strong competition, the
                  Company has been increasing performance and continued to take
                  initiatives to move up the quality besides cost reduction and
                  cost control initiatives.
                </li>
                <li>
                  Political environment: Considering its basic political
                  philosophy, we are reviewing existing and future investment
                  strategies on a continuous basis. Risks that are likely to
                  emanate are managed by constant engagement with the Government
                  of the day, reviewing and monitoring the country’s industrial,
                  labour and related policies.
                </li>
                <li>
                  Environmental clearances: The management of the Company
                  recognizes its employees as the company’s most important
                  assets. The Company has introduced a number of safety measures
                  across all its manufacturing Units and is continuously working
                  towards achieving international benchmarks in safety
                  standards. The said safety standards can be demonstrated by
                  the fact that our manufacturing facilities have been awarded
                  OHSAS 18001:2007 (Occupational Health and Safety)
                  certification.
                </li>
                <li>
                  Raw Material Risk: Proper policies are followed in relation to
                  maintenance of inventories of raw materials, consumables, key
                  spares and tools to ensure their availability for planned
                  generation programmes. The Company is continuously exploring
                  opportunities for hedging the cost of LLP through long term
                  contracts, expanding existing storage facilities for LLP,
                  adding new suppliers etc. The volatility in costs for
                  packaging materials (another key input) is being offset
                  through advance contracts and developing new suppliers for
                  packaging materials.
                </li>
                <li>
                  Commercial Risks: Proper billing process and follow up with
                  customer is required to reduce time-lag between billing and
                  payment by customers.
                </li>
                <li>
                  Liquidity Risk: Proper financial planning is put in place with
                  detailed Annual Business Plans discussed at appropriate levels
                  within the organisation. Annual and quarterly budgets are
                  prepared and put up to management for detailed discussion and
                  an analysis of the nature and quality of the assumptions,
                  parameters etc. These budgets with Variance Analysis are
                  prepared to have better financial planning and study of
                  factors giving rise to variances. Monthly/Quarterly/Annual
                  cash flows are prepared, followed and monitored at senior
                  levels to prevent undue loss of interest and utilise cash in
                  an effective manner.
                </li>
                <li>
                  Technical, quality or performance risk: Attract and retain
                  quality technical associates and augmenting their training.
                  Implementation of quality control measures to ensure that the
                  raw material is of desired quality. These risks are minimized
                  by Organisation and Management of product manufacturing
                  process Schedules, such as planning, monitoring and reporting
                  systems in the day to day operation process
                </li>
                <li>
                  Logistics risks: Background of employee is checked to ensure
                  that he is suitable for remote location and that re location
                  should result in minimum discomfort and he can contribute
                  positively.
                </li>
                <li>
                  Dispute Resolution and arbitration.: A study of contracts with
                  focus on contractual liabilities, deductions, penalties and
                  interest conditions is undertaken on a regular basis. The
                  legal department vets all legal and contractual documents with
                  legal advice from Legal retainers for different branches of
                  legislation. Contracts are finalized as per the advice from
                  legal professionals and advocates. Carefully drafted contracts
                  avoid disputes and also allow to reach amicable solution with
                  the help of Arbitration as and when required.
                </li>
                <li>
                  Financial reporting risks: Our Company is committed to
                  maintaining high standards of corporate governance and public
                  disclosure and our efforts to comply with evolving laws,
                  regulations and standards in this regard would further help us
                  address these issues. Our preparation of financial statements
                  in conformity with Indian Accounting Standards and in
                  accordance with the Accounting Standards issued by NFRA,
                  requires us to make estimates and assumptions that affect the
                  reported amount of assets and liabilities, disclosure of
                  contingent assets and liabilities at the date of our financial
                  statements and the reported amounts of revenue and expenses
                  during the reporting period. Management bases its estimates
                  and judgments on historical experience and on various other
                  factors that are believed to be reasonable under the
                  circumstances including consultation with experts in the
                  field, scrutiny of published data for the particular sector or
                  sphere, comparative study of other available corporate data,
                  the results of which form the basis for making judgments about
                  the carrying values of assets and liabilities that are not
                  readily apparent from other sources. These may carry inherent
                  reporting risks.
                </li>
                <li>
                  Risk of Corporate accounting fraud: The Company mitigates this
                  risk by Understanding the applicable laws and regulations ,
                  Conducting risk assessments, Enforcing and monitoring code of
                  conduct for key executives , Instituting Whistleblower
                  mechanisms , Deploying a strategy and process for implementing
                  the new controls, Adhering to internal control practices that
                  prevent collusion and concentration of authority, Employing
                  mechanisms for multiple authorisation of key transactions with
                  cross checks , Scrutinising of management information data to
                  pinpoint dissimilarity of comparative figures and ratios,
                  Creating a favourable atmosphere for internal auditors in
                  reporting and highlighting any instances of even minor non-
                  adherence to procedures and manuals and a host of other steps
                  throughout the organisation and assign responsibility for
                  leaving the overall effort to a senior individual like Chief
                  Financial Officer.
                </li>
                <li>
                  Legal risk: We have an experienced team of professionals,
                  advisors who focus on evaluating the risks involved in a
                  contract, ascertaining our responsibilities under the
                  applicable law of the contract, restricting our liabilities
                  under the contract, and covering the risks involved so that
                  they can ensure adherence to all contractual commitments.
                  Management places and encourages its employees to place full
                  reliance on professional guidance and opinion and discuss
                  impact of all laws and regulations to ensure company’s total
                  compliance. Advisories and suggestions from professional
                  agencies and industry bodies, chambers of commerce etc. are
                  carefully studied and acted upon where relevant. The Company
                  has established a compliance management system in the
                  organisation and Secretary of the Company being the focal
                  point will get the quarterly compliance reports from
                  functional heads and being placed before the Board.
                </li>
                <li>
                  Human resource management risk: Labour Turnover Risks,
                  involving replacement risks, training risks, skill risks, etc.
                  The Company has proper recruitment policy for recruitment of
                  personnel at various levels in the organization. Proper
                  appraisal system for revision of compensation on a periodical
                  basis has been evolved and followed regularly. Employees are
                  trained at regular intervals to upgrade their skills. Labour
                  problems are obviated by negotiations and conciliation.
                  Activities relating to the Welfare of employees are
                  undertaken. Employees are encouraged to make suggestions and
                  discuss any problems with their Superiors. To restrict the
                  impact of attrition of talent at top management level, Second
                  level positions are created in each Department to continue the
                  work without any interruption in case of non-availability of
                  functional heads.
                </li>
                <li>
                  System Risk: IT department maintains repairs and upgrades the
                  systems on a continuous basis with personnel who are trained
                  in software and hardware on regular basis. Password protection
                  is provided at different levels to ensure data integrity.
                  Licensed software is being used in the systems. The Company
                  ensures “Data Security”, by having access control/
                  restrictions. Flow of information is well defined to avoid any
                  conflict or communication gap between two or more Departments.
                </li>
                <li>
                  Disaster Risks: The properties of the company are insured
                  against natural risks, like fire, flood, earthquakes, etc.
                  with periodical review of adequacy, rates and risks covered
                  under professional advice. Fire extinguishers have been placed
                  at fire sensitive locations. First aid training is given to
                  watch and ward staff and safety personnel. Workmen of the
                  company are covered under ESI, EPF, etc., to serve the welfare
                  of the workmen.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Review</h3>
              <p>
                Review of this Risk Management Policy -- including compliance
                with internal controls under various functional areas – is to be
                undertaken by the Board with the views of other Directors from
                time to time so as to ensure effectiveness of the policy. Such
                review would be made with the presentation of the Risk
                Identification Report above and Risk Assessment & Minimization
                Report mentioned -- unless a more frequent review is deemed
                necessary. The recommendations arising out of the review are to
                be placed to the Board for effecting / implementing changes.
              </p>
              <ul className="list-decimal pl-6">
                <p>The essential part of the review mechanism would be:</p>
                <li>
                  Submission of Risk Report to the Board on an annual basis to
                  highlight fresh risks as well as Action-taken Reports on
                  previously identified risks.
                </li>
                <li>
                  Monitoring the implementation of Risk-mitigation Action
                  Plan(s) to ensure sensitivity to detection of risks,
                  flexibility in response.
                </li>
                <li>
                  Evaluating success of the Risk-mitigation Action Plan(s)
                </li>
                <li>
                  Overall Review of the Risk Management Policy and modification
                  of policy from time to time arising out of the review.
                </li>
              </ul>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
}

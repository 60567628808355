import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const isUser = localStorage.getItem("isLoggedIn") === "true";

  return isUser ? element : <Navigate to="/cs" replace />;
};

export default ProtectedRoute;

import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AiFillFilePdf } from "react-icons/ai";
import "../utils/styles/accordion.css";
import { AccordionProps } from "../helpers/types";
import { handleFileUrl } from "helpers/dataFormating";

const Accordion = ({ items, initialOpenIndex }: AccordionProps) => {
  const [openIndex, setOpenIndex] = useState<number | null>(initialOpenIndex);

  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [booleanArray, setBooleanArray] = useState<boolean[]>([]);

  useEffect(() => {
    const result = items.map((elem) =>
      elem.quarters.map((quarterLabel) =>
        quarterLabel.label.toLowerCase().includes("quarter")
      )
    );

    // You can update the array with a new array of boolean values
    setBooleanArray([...result[0]]);

    console.log("booleanArray :", booleanArray.includes(true));
  }, []);

  const handleToggle = (index: number) => {
    // Toggle the accordion: If clicked accordion is already open, close it; otherwise, open it
    setOpenIndex(openIndex === index ? null : index);
  };
  // console.log("items", items);

  useEffect(() => {
    // Dynamically adjust the height of the content for the opened accordion
    contentRefs.current.forEach((content, index) => {
      if (content) {
        // Set height based on whether the accordion is open or not
        content.style.height =
          openIndex === index ? `${content.scrollHeight}px` : "0";
      }
    });
  }, [openIndex, items]); // Re-run on openIndex change to update the content height

  return (
    <div className="space-y-2 mb-8">
      {items.map((item, index) => (
        <div key={index} className="border border-gray-200 rounded-lg">
          {/* Accordion Header */}
          <button
            onClick={() => handleToggle(index)}
            className={`w-full px-4 py-3 text-left bg-gray-50 rounded-t-lg flex justify-between items-center focus:outline-none ${
              openIndex === index ? "bg-[#d9d9d9]" : "bg-gray-50"
            }`}
          >
            <span
              className={`transition-colors duration-300 ${
                openIndex === index ? "text-primary" : "text-slate-900"
              }`}
            >
              {item.title}
            </span>
            <div className="flex items-center gap-4">
              {/* Arrow Icon */}
              <span>
                {openIndex === index ? (
                  <FaChevronUp className="w-9 h-9 bg-primary rounded-image shadow-md text-white text-sm p-3" />
                ) : (
                  <FaChevronDown className="w-9 h-9 bg-gray-200 rounded-image shadow-md text-slate-900 text-xs p-3" />
                )}
              </span>
            </div>
          </button>

          {/* Accordion Content */}
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className="overflow-hidden transition-all duration-300 ease-in-out"
            style={{
              height:
                openIndex === index
                  ? `${contentRefs.current[index]?.scrollHeight}px`
                  : "0px",
            }}
          >
            <div className="px-4 py-4 bg-white">
              <div
                className={`${
                  booleanArray.includes(true)
                    ? "grid grid-cols-2 gap-4"
                    : "flex flex-col"
                }`}
              >
                {item.quarters.map((quarter, quarterIndex) => {
                  return (
                    <>
                      {/* {console.log("quarter :", isQuarter(quarter.label))} */}

                      <div key={quarterIndex}>
                        <label className="text-lg font-medium mb-3 text-left text-primary block px-4">
                          {quarter.label !== "None" && quarter.label}
                        </label>
                        <div className="mb-2">
                          <div
                            className={
                              booleanArray.includes(true)
                                ? `space-y-6 px-4 custBorder`
                                : `grid grid-cols-2 gap-4`
                            }
                          >
                            {quarter.reports.map((report, reportIndex) => (
                              <div
                                key={reportIndex}
                                className={
                                  booleanArray.includes(true)
                                    ? ` `
                                    : `text-center px-4 !mt-0 custBorder`
                                }
                              >
                                <a
                                  href={report.file}
                                  className="text-slate-900 hover:text-slate-950 qtpdfBtn"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <AiFillFilePdf className="mr-1 pdf-icon" />
                                  {handleFileUrl(report.file)}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;

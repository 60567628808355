import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { loginApi } from "utils/apis";

interface AuthState {
  data: any | null;
  loading: boolean;
  error: string | null;
  status: number;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
  isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
};

// Async thunk for login
export const loginData = createAsyncThunk(
  "auth/login",
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await loginApi(body);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message || "Login failed");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.isLoggedIn = true;
      localStorage.setItem("isLoggedIn", "true");
    },
    logout: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
      state.status = 0;
      state.isLoggedIn = false;
      localStorage.setItem("isLoggedIn", "false");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 0;
      })
      .addCase(loginData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.status = 1;
        state.isLoggedIn = true; // Optional: Can be handled via reducers too
        localStorage.setItem("isLoggedIn", "true");
        console.log("state ===>", state.data);
      })
      .addCase(loginData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.status = 0;
        localStorage.setItem("isLoggedIn", "false");
      });
  },
});

// Export actions
export const { loginSuccess, logout } = authSlice.actions;

// Export the reducer as default
export default authSlice.reducer;

// import { useState, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";

import Container from "components/Container";
import { useEffect, useState } from "react";
import UploadDocModal from "components/UploadDocModal";
import { InversterDoc } from "helpers/types";
import { FiUpload } from "react-icons/fi";
import { AnnouncementApiFetch } from "utils/apis";

export default function Announcements() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [announcementDoc, setAnnouncementDoc] = useState<InversterDoc[]>([]);

  const isUser = localStorage.getItem("isLoggedIn") === "true";
  // console.log("isUser ======>", isUser);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  console.log("announcementDoc", announcementDoc);

  const handleAnnouncementDataGetting = async () => {
    const response = await AnnouncementApiFetch(1);
    setAnnouncementDoc(response?.data);
  };

  useEffect(() => {
    handleAnnouncementDataGetting();
  }, []);

  return (
    <>
      <BreadCrumb pageName="Announcements" />
      {/* {console.log("isUser ======>", isUser)} */}
      <Container>
        <div className="flex items-center justify-between">
          <InvestorsCorner title="Announcements" />
          <div className="flex items-center gap-2">
            {isUser && (
              <button
                onClick={toggleModal}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-52 flex items-center gap-4"
              >
                <FiUpload /> Upload Documents
              </button>
            )}
          </div>
        </div>
        <div className="mb-8">
          <TabPanel
            tabsData={announcementDoc}
            investorHrefName="announcement"
          />
        </div>
      </Container>
      {/* <TabPanel tabsData={announcementData} /> */}

      {/* Upload Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-[#f1f5f9] p-6 relative customShadow rounded-xl w-[400px]">
            <UploadDocModal
              isOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              title="Announcements"
              selectedInvestId={1}
              // showApiFetch={AnnouncementApiFetch}
            />
          </div>
        </div>
      )}
    </>
  );
}

import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import advicetoShareholdersData from "../utils/data/advicetoShareholdersData";
import UploadDocModal from "components/UploadDocModal";
import { IoMdClose } from "react-icons/io";
import Container from "components/Container";
import { useSelector, useDispatch } from "react-redux";
// import { selectIsLoggedIn } from "../redux/authSlice";
// import {
//   toggleUploadDocModal,
//   closeUploadDocModal,
//   selectIsUploadDocModalOpen,
//   selectModalName,
//   selectInvestId,
// } from "../redux/modalSlice";

export default function AdviceShareholders() {
  const dispatch = useDispatch();
  // const isUploadDocModalOpen = useSelector(selectIsUploadDocModalOpen);
  // const modalName = useSelector(selectModalName);
  // const selectedInvestId = useSelector(selectInvestId);
  // const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <>
      <BreadCrumb pageName="AdviceShareholders" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Advice to Shareholders" />
      </div>
    </>
  );
}

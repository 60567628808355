import React from "react";
import { AiFillFilePdf } from "react-icons/ai";
import { InversterDoc } from "../helpers/types";
import "../utils/styles/accordion.css"; // Import styles
import { handleFileUrl } from "helpers/dataFormating";

interface NonAccordionProps {
  data: InversterDoc[];
}

const NonAccordion: React.FC<NonAccordionProps> = ({ data }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
      {data.map((item, index) => (
        <div key={index} className="text-center">
          <a
            href={item.fileUrl} // Assuming fileUrl holds the file link
            target="_blank"
            rel="noopener noreferrer"
            className="qtpdfBtn"
          >
            <AiFillFilePdf className="mr-1 pdf-icon" />
            {handleFileUrl(item.fileUrl)}
          </a>
        </div>
      ))}
    </div>
  );
};

export default NonAccordion;

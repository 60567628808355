import React, { useState, useRef } from "react";
import "../utils/styles/Dropdown.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface Option {
  label: string;
  value: string | number;
}

interface DropdownProps {
  options: Option[];
  value: string;
  onSelect: (option: Option) => void;
  className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  value,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [selectedOption, setSelectedOption] = useState<string | null>(null); // Start with null
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option: Option) => {
    // setSelectedOption(option.label); // Store the label as the selected option
    // onSelect(option.value.toString()); // Pass the value to the onSelect handler
    onSelect(option); // Pass the full Option object to the onSelect handler
    setIsOpen(false);
  };

  // Event handler for clicks outside the dropdown
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Find the selected option based on the current value
  const selectedOption = options.find(
    (option) => option.value.toString() === value
  );

  return (
    <div className={`dropdown ${className}`} ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedOption ? selectedOption.label : "Select an option"}{" "}
        {/* Placeholder text */}
        {isOpen ? (
          <IoIosArrowUp className="arrow" />
        ) : (
          <IoIosArrowDown className="arrow" />
        )}
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <div
              key={option.value}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label} {/* Display the label here */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

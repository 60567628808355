import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import unclaimedDividendData from "../utils/data/unclaimedDividendData";

export default function UnclaimedDividend() {
  return (
    <>
      <BreadCrumb pageName="UnclaimedDividend" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Unclaimed Dividend" />
        {/* <TabPanel tabsData={unclaimedDividendData} /> */}
      </div>
    </>
  );
}

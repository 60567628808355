import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/redux/store";

import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollButton from "./components/ScrollButton";
import BoardOfDirectors from "./pages/BoardOfDirectors";
import VisionMission from "./pages/VisionMission";
import Milestones from "./pages/Milestones";
import OurStory from "./pages/OurStory";
import ReadMore from "./pages/ReadMore";
import WhatWeDo from "./pages/WhatWeDo";
import OurTeam from "./pages/OurTeam";
import Career from "./pages/Career";
import JobDetails from "./pages/JobDetails";

import ContactUs from "./pages/ContactUs";
import ProjectPage from "./pages/ProjectPage";
import { projectData } from "./utils/data/projectData";
import LegalDisclaimer from "./pages/LegalDisclaimer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundCancellations from "./pages/RefundCancellations";
import Foreclosures from "./pages/Foreclosures";
import DataRetentionPolicy from "./pages/DataRetentionPolicy";
import KeyManagement from "./pages/KeyManagement";
import PayEmi from "./pages/PayEmi";
import NotFound from "./pages/NotFound";

import CSLogin from "./pages/CS";
import InvestorListPage from "./pages/InvestorListPage";
import ProtectedRoute from "./components/ProtectedRoute";

import Announcements from "./pages/Announcements";
import CompositionCommittee from "./pages/CompositionCommittee";
import CorporateGovernanceReport from "./pages/CorporateGovernanceReport";
import MaterialityEvents from "./pages/MaterialityEvents";
import AnnualSecretarialCompliance from "./pages/AnnualSecretarialCompliance";
import SchemeofArrangement from "./pages/SchemeofArrangement";
import AdvicetoShareholders from "./pages/AdviceShareholders";
import FinancialResults from "./pages/FinancialResults";
import ShareholdingPattern from "./pages/ShareholdingPattern";
import CodeofConductPolicies from "./pages/CodeofConductPolicies";
import AnnualReturn from "./pages/AnnualReturn";
import MonitoringAgencyReport from "./pages/MonitoringAgencyReport";
import UnclaimedDividend from "./pages/UnclaimedDividend";
import InvestorComplaints from "./pages/InvestorComplaints";
import ReconciliationShareCapital from "./pages/ReconciliationofShareCapital";
import RelatedPartyTransactionPolicy from "pages/RelatedPartyTransactionPolicy";
import WebsiteContentArchivalPolicy from "pages/WebsiteContentArchivalPolicy";
import RiskManagementPolicy from "pages/RiskManagementPolicy";
import TermsConditions from "pages/TermsConditions";

export default function App() {
  const isUser = localStorage.getItem("isLoggedIn") === "true";
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/about-us" element={<OurStory />} />
          <Route path="/board-of-directors" element={<BoardOfDirectors />} />
          <Route path="/mission-vision" element={<VisionMission />} />
          <Route path="/milestones" element={<Milestones />} />
          <Route path="/read-more" element={<ReadMore />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/our-teams" element={<OurTeam />} />
          <Route path="/job-openings" element={<Career />} />

          <Route path="/contact" element={<ContactUs />} />
          <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/refund-cancellation"
            element={<RefundCancellations />}
          />
          <Route path="/foreclosure-cancellations" element={<Foreclosures />} />
          <Route
            path="/data-retention-policy"
            element={<DataRetentionPolicy />}
          />
          <Route
            path="/key-management-personnel-and-management-team"
            element={<KeyManagement />}
          />
          <Route
            path="/ashapurti-loans"
            element={<ProjectPage data={projectData[0]} />}
          />
          <Route
            path="/bade-bhaisab"
            element={<ProjectPage data={projectData[2]} />}
          />
          <Route
            path="/saral-sahayak"
            element={<ProjectPage data={projectData[1]} />}
          />
          <Route
            path="/india-fdi"
            element={<ProjectPage data={projectData[4]} />}
          />
          <Route
            path="/xoom-pay"
            element={<ProjectPage data={projectData[5]} />}
          />
          <Route
            path="/farmedinindia"
            element={<ProjectPage data={projectData[6]} />}
          />

          {/* Investor Corners Menu start */}
          <Route path="/announcements" element={<Announcements />} />
          <Route
            path="/composition-of-committee"
            element={<CompositionCommittee />}
          />
          <Route
            path="/corporate-governance-report"
            element={<CorporateGovernanceReport />}
          />

          <Route
            path="/annual-secretarial-compliance-report"
            element={<AnnualSecretarialCompliance />}
          />

          <Route path="/financial-results" element={<FinancialResults />} />
          <Route
            path="/shareholding-pattern"
            element={<ShareholdingPattern />}
          />
          <Route
            path="/code-of-conduct-policies"
            element={<CodeofConductPolicies />}
          />
          <Route path="/annual-return" element={<AnnualReturn />} />
          <Route path="/investor-complaints" element={<InvestorComplaints />} />
          <Route
            path="/reconciliation-share-capital"
            element={<ReconciliationShareCapital />}
          />

          <Route path="/" element={<MaterialityEvents />} />
          <Route path="/" element={<SchemeofArrangement />} />
          <Route path="/" element={<AdvicetoShareholders />} />
          <Route path="/" element={<MonitoringAgencyReport />} />
          <Route path="/" element={<UnclaimedDividend />} />
          <Route path="/" element={<UnclaimedDividend />} />

          {/* Investor Corners Menu end */}

          <Route path="/pay-emi" element={<PayEmi />} />
          <Route path="/job-openings/:jobId" element={<JobDetails />} />

          <Route path="/cs" element={<CSLogin />} />

          {/* Protected Route */}
          <Route
            path="/investorlistpage"
            element={<ProtectedRoute element={<InvestorListPage />} />}
          />
          {/* {isUser && (
            <Route path="/investorlistpage" element={<InvestorListPage />} />
          )} */}
          {/* Catch-All Route (Optional) */}
          <Route path="*" element={<Navigate to="/cs" replace />} />

          <Route
            path="/related-party-transaction-policy"
            element={<RelatedPartyTransactionPolicy />}
          />
          <Route
            path="/website-content-archival-policy"
            element={<WebsiteContentArchivalPolicy />}
          />
          <Route
            path="/risk-management-policy"
            element={<RiskManagementPolicy />}
          />
          <Route path="/terms-conditions" element={<TermsConditions />} />
        </Routes>
        <ScrollButton />
        <Footer />
      </BrowserRouter>
    </Provider>
  );
}

import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import annualSecretarialComplianceData from "../utils/data/annualSecretarialComplianceData";

export default function MonitoringAgencyReport() {
  return (
    <>
      <BreadCrumb pageName="MonitoringAgencyReport" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Monitoring Agency Report" />
        {/* <TabPanel tabsData={annualSecretarialComplianceData} /> */}
      </div>
    </>
  );
}

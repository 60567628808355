import Container from "components/Container";

export default function RefundCancellations() {
  return (
    <>
      <section className="my-8 inner-content inner-content">
        <Container>
          <h1 className="font-bold text-3xl text-center md:text-left">
            Refund & Cancellation
          </h1>
          <h3 className="text-center font-bold text-xl text-gray-600 mt-8">
            We do not have refunds
          </h3>
          <h3 className="text-center font-bold text-2xl text-green-500 mt-8">
            Please Refer To Foreclosure & Cancellation
          </h3>
        </Container>
      </section>
    </>
  );
}

import Container from "components/Container";

export default function RelatedPartyTransactionPolicy() {
  return (
    <>
      <section className="my-8 inner-content">
        <Container>
          <h1 className="font-bold text-2xl mb-4">
            Policy On Related Party Transactions
          </h1>
          <ul className="list-decimal pl-6 text-md font-bold  space-y-4">
            <li className="space-y-3">
              <h3 className="font-bold text-md">Background</h3>
              <p>
                Related party transactions can present a potential or actual
                conflict of interest which may be against the best interest of
                the company and its shareholders. Considering the requirements
                for approval of related party transactions as prescribed under
                the Companies Act, 2013 (“Act”) read with the Rules framed there
                under and Regulation 23 of the Securities and Exchange Board of
                India (Listing Obligations and Disclosure Requirements)
                Regulations, 2015 (“SEBI Listing Regulations”), Golden Legand
                Leasing & Finance Ltd. (“GLLFL” or “the Company”) is required to
                formulate a Policy for identification of related parties and the
                proper conduct and documentation of all related party
                transactions.
              </p>
              <p>
                Further, Regulation 23(1) of the SEBI Listing Regulations
                requires a company to formulate a policy on materiality of
                related party transactions and dealing with related party
                transactions.
              </p>
              <p>
                In light of the above, GLLFL has framed this Policy on Related
                Party Transactions (“Policy”) to ensure high standards of
                Corporate Governance while dealing with related parties.
              </p>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Objective of the Policy</h3>
              <p>
                The objective of this Policy is to set out (a) the materiality
                thresholds for related party transactions;
              </p>
              <ul className="list-disc pl-6 font-normal">
                <li className="space-y-3">
                  the materiality thresholds for related party transactions;
                </li>
                <li className="space-y-3">
                  the manner of dealing with the transactions between the
                  Company and its related parties based on the Act, the SEBI
                  Listing Regulations and any other laws and regulations as may
                  be applicable to the Company; and
                </li>
                <li className="space-y-3">
                  lay down the guiding principles and mechanism to ensure proper
                  approval, disclosure and reporting of transactions as
                  applicable, between the Company and any of its related parties
                  in the best interest of the Company and its stakeholders.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Definitions</h3>
              <p>
                All terms used in this Policy will have the meanings as assigned
                to them under the Act and the Rules made thereunder, SEBI
                Listing Regulations and applicable Accounting Standards, as
                amended from time to time. 4. Materiality Thresholds
              </p>
              <p>
                Regulation 23 of the SEBI Listing Regulations requires a company
                to lay down materiality thresholds for transactions beyond which
                approval of the shareholders through a resolution will be
                required.
              </p>
              <ul className="list-disc pl-6 font-normal">
                <p>
                  GLLFL has fixed its materiality thresholds at the level
                  prescribed under explanation to Regulation 23(1) of the SEBI
                  Listing Regulations as under:
                </p>
                <li className="space-y-3">
                  In case of transaction involving payments made to a related
                  party with respect to brand usage or royalty, if the
                  transaction(s) to be entered into individually or taken
                  together with previous transactions during a financial year,
                  exceeds five percent (5%) of the annual consolidated turnover
                  of the Company as per its last audited financial statements.
                </li>
                <li className="space-y-3">
                  In case of any other Transaction, if the amount of the
                  transaction(s) to be entered into individually or taken
                  together with previous transactions during a financial year,
                  exceeds (Rs. 1,000 crore or)_ 10% of the annual consolidated
                  turnover of the Company as per its last audited financial
                  statements, (whichever is lower)_. \*Effective April 1, 2022.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">
                Manner of dealing with related party transactions
              </h3>
              <ul className="list-disc pl-6 font-normal space-y-4">
                <li className="space-y-3">
                  <h3 className="font-bold text-md">
                    Identification of related parties
                  </h3>
                  <p>
                    The Company has formulated a Framework for identification
                    and updating the list of related parties as prescribed under
                    Section 2(76) of the Act read with the Rules framed there
                    under, Regulation 2(1)(zb) of the SEBI Listing Regulations
                    and applicable Accounting Standards, as amended from time to
                    time.
                  </p>
                </li>
                <li className="space-y-3">
                  <h3 className="font-bold text-md">
                    Identification of related party transactions
                  </h3>
                  <p>
                    The Company has formulated a Framework for identification of
                    related party transactions in accordance with Section 188 of
                    the Act and Rules framed thereunder, and Regulation 2(1)(zc)
                    of the SEBI Listing Regulations. The Company has also
                    formulated a Framework for determining whether the
                    transaction is in the ordinary course of business and at
                    arm’s length basis and for this purpose, the Company may
                    seek external professional opinion, if necessary.
                  </p>
                </li>
                <li className="space-y-3">
                  <h3 className="font-bold text-md">
                    Review and approval of related party transactions
                  </h3>
                  <ul className="list-decimal pl-6 font-normal space-y-4">
                    <li className="space-y-3">
                      Every related party transaction shall be subject to the
                      prior approval of the Audit Committee whether at a meeting
                      or by resolution by way of circulation. Provided that only
                      those members of the Audit Committee, who are Independent
                      Directors, shall approve such related party transactions.
                      Further, any member of the Audit Committee who has a
                      potential interest in any related party transaction shall
                      abstain from discussion and voting on the approval of the
                      related party transaction.
                    </li>
                    <li className="space-y-3">
                      However, the Audit Committee may grant omnibus approval
                      for recurring transactions with related parties, in
                      compliance with requirements of the Act and the SEBI
                      Listing Regulations. The Audit Committee shall review /
                      note on a quarterly basis the details of such related
                      party transactions entered into by GLLFL pursuant to each
                      of the omnibus approval given. The omnibus approval shall
                      be valid for a period of one year. Where the need for
                      related party transaction cannot be foreseen and requisite
                      details are not available, Audit Committee may grant
                      omnibus approval for such transactions subject to their
                      value not exceeding rupees one crore per transaction.
                    </li>
                    <li className="space-y-3">
                      The Audit Committee shall determine whether the
                      transaction does, in fact, constitute a related party
                      transaction requiring compliance with this Policy.
                    </li>
                    <li className="space-y-3">
                      Approval Matrix for related party transaction is, as
                      under:
                      <ul className="list-disc pl-6 font-normal space-y-4 mt-4">
                        <li className="space-y-3">
                          <b>Audit Committee Approval</b>
                          <p>
                            All Related Party Transactions be approved vide a
                            resolution or covered under the Omnibus approval
                            mechanism
                          </p>
                        </li>
                        <li className="space-y-3">
                          <b>Board Approval</b>
                          <ul className="list-disc pl-6">
                            <li className="space-y-3">
                              Related Party Transactions referred by Audit
                              Committee for approval of the Board
                            </li>
                            <li className="space-y-3">
                              Related Party Transactions, to be approved by the
                              Board, as required under the applicable Law /
                              Regulations applicable to the Company
                            </li>
                            <li className="space-y-3">
                              Related Party Transactions requiring shareholders’
                              approval
                            </li>
                          </ul>
                        </li>
                        <li className="space-y-3">
                          <b>Shareholders’ Approval*</b>
                          <b className="pl-2">Approval by resolution for:</b>
                          <ul className="list-decimal pl-6 font-normal">
                            <li className="space-y-3">
                              Material Related Party Transactions
                            </li>
                            <li className="space-y-3">
                              <p>
                                Related Party Transactions not in Ordinary
                                Course of Business or not on Arm's length basis
                                and crosses threshold limit as prescribed under
                                the Act / Regulations applicable to the Company
                              </p>
                            </li>
                            <li className="space-y-3">
                              <b>Notes:</b>
                              <p>
                                No related party shall vote to approve relevant
                                shareholders resolutions irrespective of whether
                                the entity is a related party to the particular
                                transaction or not.
                              </p>
                              <p>
                                The requirement for seeking shareholders’
                                approval shall not be applicable to transactions
                                as specified in the Framework for Related Party
                                Transactions.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Disclosures</h3>
              <ul className="pl-6 list-disc space-y-4 font-normal">
                <li className="space-y-3">
                  The Board’s Report will contain details of contracts or
                  arrangements or transactions (i) not at arm’s length basis and
                  (ii) material (based on the thresholds laid down under Rule 15
                  of the Companies (Meetings of Board and its Powers) Rules,
                  2014) contracts or arrangement or transactions at arm’s length
                  basis and/or in ordinary course of business, along with
                  justification for entering into such transaction in accordance
                  with the requirements of Companies Act, 2013 and rules made
                  thereunder.
                </li>
                <li className="space-y-3">
                  The Company shall submit disclosures of related party
                  transactions along with the financial results for the half
                  year on a standalone / consolidated basis, in the format
                  specified by the SEBI, from time to time, and publish the same
                  on its website.
                </li>
                <li className="space-y-3">
                  The Company shall also provide details of all material related
                  party transactions (laid down in Clause 4 of the Policy above)
                  on a quarterly basis to the stock exchanges along with the
                  Compliance Report on Corporate Governance.
                </li>
                <li className="space-y-3">
                  As prescribed under Regulation 62(1A)(g) of the SEBI Listing
                  Regulations, this Policy shall be hosted on the Company’s
                  website at https://gllfl.com/.
                </li>
              </ul>
            </li>
            <li className="space-y-3">
              <h3 className="font-bold text-md">Review of the Policy</h3>
              <p>
                This Policy shall be reviewed by the Board as and when any
                changes are to be incorporated in the Policy due to change in
                applicable law or at least once in every three years and updated
                accordingly based on the recommendations of the Audit Committee.
              </p>
              <p>
                Provisions of this Policy would be subject to revision /
                amendment in accordance with the Rules, Regulations,
                Notifications, etc. on the subject as may be issued by relevant
                statutory authorities, from time to time. In case of any
                amendment(s), clarification(s), circular(s), etc. issued by the
                relevant authorities, not being consistent with the provisions
                laid down under this Policy, then such amendment(s),
                clarification(s), circular(s), etc. shall prevail upon the
                provisions hereunder and this Policy shall stand amended
                accordingly from the effective date as laid down under such
                amendment(s), clarification(s), circular(s), etc.
              </p>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
}
